var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"onboarding-page-container"},[_c('div',{staticClass:"side column is-4"},[_c('BackButton',{attrs:{"fallback":'SPACES'}}),_c('ul',{staticClass:"menu"},[_c('li',{class:{
          'menu-item': true,
          ready: _vm.ready.details
        }},[_c('router-link',{staticClass:"menu-item-link venue-details-link",attrs:{"to":{ name: _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.DETAILS },"exact":""}},[(_vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.DETAILS)?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs"}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('onboarding.venue.side_menu.label_details_link')))])]),_c('span',{staticClass:"icon"},[(_vm.ready.details)?_c('img',{attrs:{"src":"/img/common/icon_ready.svg","alt":"ready","loading":"lazy"}}):_vm._e()])],1),_c('li',{class:{
          'menu-item': true,
          ready: _vm.ready.facilities
        }},[(_vm.ready.facilities)?_c('router-link',{staticClass:"menu-item-link venue-facilities-link",attrs:{"to":{ name: _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.FACILITIES },"exact":""}},[(
              _vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.FACILITIES
            )?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs"}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('onboarding.venue.side_menu.label_facilities_link')))])]):_c('div',{staticClass:"menu-item-link venue-facilities-link",class:{
            'router-link-exact-active':
              _vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.FACILITIES
          },style:('cursor: default')},[(
              _vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.FACILITIES
            )?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs"}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('onboarding.venue.side_menu.label_facilities_link')))])]),_c('span',{staticClass:"icon"},[(_vm.ready.facilities)?_c('img',{attrs:{"src":"/img/common/icon_ready.svg","alt":"ready","loading":"lazy"}}):_vm._e()])],1),_c('li',{class:{
          'menu-item': true,
          ready: _vm.ready.catering
        }},[(_vm.ready.catering)?_c('router-link',{staticClass:"menu-item-link venue-catering-link",attrs:{"to":{ name: _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.CATERING },"exact":""}},[(_vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.CATERING)?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs"}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('onboarding.venue.side_menu.label_catering_link')))])]):_c('div',{staticClass:"menu-item-link venue-catering-link",class:{
            'router-link-exact-active':
              _vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.CATERING
          },style:('cursor: default')},[(_vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.CATERING)?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs"}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('onboarding.venue.side_menu.label_catering_link')))])]),_c('span',{staticClass:"icon"},[(_vm.ready.catering)?_c('img',{attrs:{"src":"/img/common/icon_ready.svg","alt":"ready","loading":"lazy"}}):_vm._e()])],1),_c('li',{staticClass:"menu-item"},[(_vm.ready.tags)?_c('router-link',{staticClass:"menu-item-link venue-tags-link",attrs:{"to":{ name: _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.TAGS },"exact":""}},[(_vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.TAGS)?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs"}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("Tags")])]):_c('div',{staticClass:"menu-item-link venue-tags-link",class:{
            'router-link-exact-active':
              _vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.TAGS
          },style:('cursor: default')},[(_vm.$route.name === _vm.ROUTE_LIST_NAMES.ONBOARDING.VENUE.TAGS)?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs"}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("Tags")])])],1)]),_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.venue.side_menu.label_info'))+" ")])],1),_c('router-view',{staticClass:"onboarding-pages column is-8",on:{"settingsChanged":_vm.isSettingsChanged}}),(_vm.showWarning)?_c('WarningModal',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.showWarning),expression:"showWarning"}],on:{"action":_vm.leavePage,"close":function($event){_vm.showWarning = false}}}):_vm._e(),(_vm.showRestore)?_c('RestoreModal',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.showRestore),expression:"showRestore"}],on:{"restore":_vm.restoreData,"close":_vm.startNewVenue}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }