import BackButton from '@/components/BackButton.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import EventBus from '@/shared/services/eventBus';
import WarningModal from '@/pages/vendor/venue/WarningModal.vue';
import RestoreModal from '@/pages/vendor/venue/RestoreModal.vue';
import { defineComponent, ref, computed, watch, onMounted, onBeforeMount } from '@vue/composition-api';
import useAsyncPage from '@/shared/composables/useAsyncPage';
export default defineComponent({
    name: 'VenueOnboardingPage',
    components: {
        BackButton,
        WarningModal,
        RestoreModal
    },
    beforeRouteLeave(to, from, next) {
        // Checking if the user can leave the page
        // if any changes on the page were made or continue button was clicked
        if (!this.ready.started ||
            this.canLeave ||
            from.params.locale !== to.params.locale) {
            next();
        }
        else {
            this.goToPath = to.fullPath;
            this.showWarning = true;
        }
    },
    setup() {
        const canLeave = ref(false);
        const goToPath = ref('');
        const ready = ref({});
        const savingProcess = ref(false);
        const showRestore = ref(false);
        const showWarning = ref(false);
        const currentStep = computed(() => {
            return globalThis.$router.history.current.name;
        });
        const unfinishedStep = computed(() => {
            return Object.keys(ready.value).find(key => !ready.value[key]);
        });
        const venueWizard = computed(() => globalThis.$store.getters['$_vendor/venueWizard']);
        onBeforeMount(() => {
            globalThis.$store.commit('$_vendor/SET_VENUE_ID', null);
        });
        const leavePage = () => {
            canLeave.value = true;
            globalThis.$router.push(goToPath.value);
            globalThis.$store.commit('$_vendor/CLEAN_VENUE_STATE_DATA');
        };
        const isSettingsChanged = (val) => {
            ready.value.started = val;
        };
        const getLocalStorageData = () => {
            const isStepCompleted = step => {
                const data = JSON.parse(localStorage.getItem(step));
                return data && data.completed;
            };
            ready.value = {
                details: isStepCompleted('venue_details'),
                facilities: isStepCompleted('venue_facilities'),
                catering: isStepCompleted('venue_catering'),
                tags: isStepCompleted('venue_tags'),
                started: !!localStorage.getItem('venue_details')
            };
        };
        const startNewVenue = () => {
            // Cleaning venue wizard data (removing from localstorage)
            globalThis.$store.commit('$_vendor/CLEAN_VENUE_DATA');
            // checking if the user is already on the first step of the wizard
            if (!currentStep.value.includes('details')) {
                // if not, redirect to the first step of the wizard
                globalThis.$router.push({
                    name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.DETAILS
                });
            }
            else {
                // Need to clean up the form;
                EventBus.$emit('startNewVenue');
            }
            // refreshing wizard checkmarks
            getLocalStorageData();
            showRestore.value = false;
        };
        const restoreData = () => {
            // checking if the user is already on the unfinished step
            if (!currentStep.value.includes(unfinishedStep.value)) {
                // if not, redirecting user to the first unfinished step of the wizard
                globalThis.$router.push({
                    name: ROUTE_LIST_NAMES.ONBOARDING.VENUE[unfinishedStep.value.toUpperCase()]
                });
            }
            EventBus.$emit('restoreVenue');
            // this.form.loadFromModel(this.venueDetails); TODO: check this line
            showRestore.value = false;
        };
        watch(() => ready.value, obj => {
            // Show restore modal if any data in localstorage
            if (!savingProcess.value)
                showRestore.value = Object.values(obj).some(val => val);
        }, { immediate: true });
        const { callAPIAction } = useAsyncPage();
        onMounted(async () => {
            EventBus.$on('updateWizardStep', () => {
                savingProcess.value = true;
                getLocalStorageData();
            });
            EventBus.$on('finishVenueCreation', () => {
                canLeave.value = true;
            });
            const [_, organisation] = await callAPIAction('$_vendor/initVendor');
            if (!(organisation && organisation.id)) {
                return globalThis.$router.replace('/vendor/onboarding/organisation-details');
            }
            // Getting data from localstorage
            getLocalStorageData();
        });
        return {
            canLeave,
            goToPath,
            ready,
            savingProcess,
            showRestore,
            showWarning,
            venueWizard,
            isSettingsChanged,
            leavePage,
            restoreData,
            startNewVenue,
            ROUTE_LIST_NAMES
        };
    }
});
